import React from 'react';

function NotFoundPage() {
    return (
        <div className="flex flex-grow flex-col items-center justify-center w-full h-full bg-slate-400">
            <div className="flex flex-row justify-center items-center w-full my-4 mt-12">
                <span className="text-4xl font-bold text-slate-800">
                    Nicht gefunden
                </span>
            </div>
            <div className="flex justify-center items-center w-full my-4">
                <span className="italic text-slate-800">
                    Diese Seite gibt es leider nicht
                </span>
            </div>
        </div>
    );
}

export default NotFoundPage;
