import React, {useState} from 'react';
import {
    GITHUB_LOGO, TD_AVAILABLE, TD_FIRING, TD_IMPACT, TD_IMPACT2, TD_LASER_CHARGE, TD_LASER_FIRING, TD_LATE,
    TD_LOGO, TD_NEXT_WAVE, TD_PAUSED, TD_TITLE_SCREEN,
} from "../../res";
import ImageModal from "../../ImageModal";

function Code({href, text}: { href: string, text: string }) {
    return (
        <a href={href} className="link font-mono text-sky-700 hover:text-sky-800">
            {text}
        </a>
    )
}

function Lnk({href, text}: { href: string, text: string }) {
    return (
        <a href={href} className="link text-blue-700 hover:text-blue-800">
            {text}
        </a>
    )
}

function Beschreibung() {
    return (
<span className="text-slate-900 font-semibold text-xl inline-block">
    Towerdefense ist ein Spiel, das ich mit dem Ziel entwickelt habe, ein einfaches, gut ausgearbeitetes Spiel ohne die
    Hilfe einer Spiele-Engine, sondern ausschließlich mit <Lnk href="https://pyga.me" text="PyGame"/> zu erstellen.
    In dem Spiel habe ich visuelle Assets
    von <Lnk href="https://pillowbyte.itch.io/diceforce-free-assets" text="Pillowbyte / pillowrd" /> und Schriftarten
    von <Lnk href="https://www.fontspace.com/azonix-font-f30718"
             text="mixofx" /> und <Lnk href="https://www.fontspace.com/pixel-tandysoft-font-f21543"
                                       text="Pixel Sagas" /> verwendet, um meinen gewünschten Kunststil zu erreichen.
    <br/>
    <br/>
    Zu den wichtigsten Klassen im Code des Spiels gehören
    die <Code href="https://github.com/michelfinley/towerdefense/blob/master/data/lib/camera.py#L8-L41" text="Canvas-" />, <Code href="https://github.com/michelfinley/towerdefense/blob/master/data/lib/camera.py#L44-L53" text="Overlay-" /> und <Code href="https://github.com/michelfinley/towerdefense/blob/master/data/lib/camera.py#L56-L177" text="Camera-" />Klassen,
    die für die korrekte Darstellung aller visuellen Elemente
    sorgen. <Lnk href="https://github.com/bitcraft/PyTMX" text="PyTMX" /> wurde in der
    Klasse <Code href="https://github.com/michelfinley/towerdefense/blob/master/data/lib/map.py" text="Map" /> verwendet, um die Tilemap zu lesen und anzuzeigen. Weitere
    wichtige Funktionen von <Code href="https://github.com/michelfinley/towerdefense/blob/master/data/lib/map.py" text="Map" /> sind die Berechnung des Weges der Feinde und
    die Limitierung der mit Verteidigungstürmen bebaubaren Fläche auf die dafür vorgesehenen Bauzonen.
    <br/>
    <br/>
    Die Hauptschleife des Spiels befindet sich in der Klasse <Code href="https://github.com/michelfinley/towerdefense/blob/master/data/scene_game.py#L152-L332" text="GameData" />, die
    auch alle Objekte und Daten verwaltet, die für den Spielablauf benötigt werden. Nahezu alle visuellen Effekte wurden
    mit dem benutzerdefinierten Partikelmanagementsystem des Spiels realisiert, das sich in der größten Datei des
    Projekts, <Code href="https://github.com/michelfinley/towerdefense/blob/master/data/lib/vfx.py" text="lib/vfx.py" />, befindet.
</span>
    )
}

function Towerdefense() {

    const [isImageModalOpen, setImageModalOpen] = useState<boolean>(false);
    const [currentImageModalImage, setCurrentImageModalImage] = useState<string>("");

    const openImageModal = () => {
        setImageModalOpen(true);
    };

    const closeImageModal = () => {
        setImageModalOpen(false);
    };

    return (
        <div className="flex flex-grow flex-col p-2 lg:p-8 w-full bg-slate-400">
            <div
                className="flex flex-col lg:flex-row items-center justify-center w-full lg:w-fit min-w-0 max-w-full my-8">
                <div className="flex items-center justify-center w-[128px] min-w-[128px] h-[128px] min-h-[128px]
                              rounded-full overflow-hidden">
                    <img src={TD_LOGO} className="" alt="TD_LOGO"/>
                </div>
                <div className="w-8 h-8"/>
                <span className="hidden sm:inline text-slate-900 font-bold text-5xl">
                    towerdefense
                </span>
                <span className="sm:hidden text-slate-900 font-bold text-3xl max-w-full truncate">
                    towerdefense
                </span>
            </div>
            <div className="my-8 min-w-0 max-w-full">
                <span className="hidden sm:inline text-slate-900 font-bold text-3xl">
                    Projektzeitraum: Dezember 2022 - Februar 2023
                </span>
                <div className="sm:hidden flex flex-col">
                    <span className="text-slate-900 font-bold text-3xl max-w-full truncate">Projektzeitraum:</span>
                    <span className="px-4 text-slate-900 font-bold text-3xl max-w-full truncate">Dez. 2022 - Feb. 2023</span>
                </div>
            </div>
            <div className="my-8">
                <span className="text-slate-900 font-bold text-3xl text-nowrap">
                    Übersicht:
                </span>
                <div className="flex flex-col-reverse lg:flex-row w-full mt-2">
                    <div className="flex flex-col mx-4">
                        <Beschreibung/>
                        <div className="my-4"/>
                        <div className="hidden md:flex flex-row h-7 text-slate-900 font-bold text-xl text-nowrap">
                            GitHub:
                            <img src={GITHUB_LOGO} className="ml-3 mr-1" alt="GITHUB"/>
                            <a href="https://github.com" className="link">michelfinley/towerdefense</a>
                        </div>
                        <div className="flex md:hidden flex-col text-slate-900 font-bold text-xl text-nowrap">
                            <div className="flex flex-row h-7">
                                <img src={GITHUB_LOGO} className="mr-1" alt="GITHUB"/>
                                <a href="https://github.com" className="link">michelfinley/towerdefense</a>
                            </div>
                        </div>
                    </div>

                    <div className="flex sm:hidden flex-col w-full p-4">
                        <div className="flex flex-col w-fit my-2">
                            <div className="text-slate-900 font-semibold text-xl font-mono">
                                Python
                            </div>
                            <div className="w-full h-[1px] bg-slate-900"/>
                            <div
                                className="flex flex-wrap justify-center items-start w-fit text-slate-900 text-base font-mono">
                                <span className="mr-2">pygame-ce</span>
                                <span className="mx-2">PyTMX</span>
                                <span className="mx-2">pillow</span>
                                <span className="mx-2">numpy</span>
                            </div>
                        </div>
                    </div>

                    <table className="hidden sm:block border-collapse w-fit h-fit m-4 lg:my-0">
                        <thead className="bg-slate-800">
                        <tr>
                            <th className="py-2 px-2 text-white font-semibold text-base">Programmiersprachen</th>
                            <th colSpan={4}
                                className="py-2 px-2 text-start text-white font-semibold text-base">Frameworks &
                                Bibliotheken
                            </th>
                        </tr>
                        </thead>
                        <tbody className="bg-slate-600
                                          text-white font-normal text-base text-nowrap">
                        <tr className="border-t border-white">
                            <td className="py-2 px-2">Python</td>
                            <td className="py-2 px-2 font-mono text-slate-300">pygame-ce</td>
                            <td className="py-2 px-2 font-mono text-slate-300">PyTMX</td>
                            <td className="py-2 px-2 font-mono text-slate-300 text-base">pillow</td>
                            <td className="py-2 px-2 font-mono text-slate-300 text-base">numpy</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="w-full my-8">
                <span className="text-slate-900 font-bold text-3xl text-nowrap">
                    Screenshots:
                </span>
                <div className="flex justify-around items-start w-full max-w-full flex-wrap">
                    <button onClick={() => {
                        setCurrentImageModalImage(TD_TITLE_SCREEN);
                        openImageModal();
                    }}
                            className="project-image-outline">
                        <img src={TD_TITLE_SCREEN} alt="TD_TITLE_SCREEN" className="project-image"/>
                    </button>
                    <button onClick={() => {
                        setCurrentImageModalImage(TD_NEXT_WAVE);
                        openImageModal();
                    }}
                            className="project-image-outline">
                        <img src={TD_NEXT_WAVE} alt="TD_NEXT_WAVE" className="project-image"/>
                    </button>
                    <button onClick={() => {
                        setCurrentImageModalImage(TD_PAUSED);
                        openImageModal();
                    }}
                            className="project-image-outline">
                        <img src={TD_PAUSED} alt="TD_PAUSED" className="project-image"/>
                    </button>
                    <button onClick={() => {
                        setCurrentImageModalImage(TD_AVAILABLE);
                        openImageModal();
                    }}
                            className="project-image-outline">
                        <img src={TD_AVAILABLE} alt="TD_AVAILABLE" className="project-image"/>
                    </button>
                    <button onClick={() => {
                        setCurrentImageModalImage(TD_IMPACT);
                        openImageModal();
                    }}
                            className="project-image-outline">
                        <img src={TD_IMPACT} alt="TD_IMPACT" className="project-image"/>
                    </button>
                    <button onClick={() => {
                        setCurrentImageModalImage(TD_IMPACT2);
                        openImageModal();
                    }}
                            className="project-image-outline">
                        <img src={TD_IMPACT2} alt="TD_IMPACT2" className="project-image"/>
                    </button>
                    <button onClick={() => {
                        setCurrentImageModalImage(TD_FIRING);
                        openImageModal();
                    }}
                            className="project-image-outline">
                    <img src={TD_FIRING} alt="TD_FIRING" className="project-image"/>
                    </button>
                    <button onClick={() => {setCurrentImageModalImage(TD_LASER_CHARGE); openImageModal();}}
                            className="project-image-outline">
                        <img src={TD_LASER_CHARGE} alt="TD_LASER_CHARGE" className="project-image"/>
                    </button>
                    <button onClick={() => {setCurrentImageModalImage(TD_LASER_FIRING); openImageModal();}}
                            className="project-image-outline">
                        <img src={TD_LASER_FIRING} alt="TD_LASER_FIRING" className="project-image"/>
                    </button>
                    <button onClick={() => {setCurrentImageModalImage(TD_LATE); openImageModal();}}
                            className="project-image-outline">
                        <img src={TD_LATE} alt="TD_LATE" className="project-image"/>
                    </button>
                </div>
            </div>
            <ImageModal isOpen={isImageModalOpen} onClose={closeImageModal} image={currentImageModalImage} />
        </div>
    );
}

export default Towerdefense;
