import React from 'react';

function Lnk({href, text}: { href: string, text: string }) {
    return (
        <a href={href} className="link text-blue-700 hover:text-blue-800">
            {text}
        </a>
    )
}


function DatenschutzSiteLink({id, text}: {id: string, text: string}) {
    return (
        <div className="mt-3 mb-1">
            <a href={`#datenschutz${id}`}>
                <span className="text-base font-bold link text-slate-800 hover:text-slate-900">{text}</span>
            </a>
        </div>
    )
}


function DatenschutzHeader({id, text}: { id: string, text: string }) {
    return (
        <div>
            <div className="h-16" id={`datenschutz${id}`}/>
            <span className="mb-3 text-slate-900 font-bold text-xl">
                {text}
            </span>
        </div>
    )
}

function DatenschutzSubHeader({id, text}: { id: string, text: string }) {
    return (
        <div className="mt-3 mb-1" id={`datenschutz${id}`}>
            <span className="text-base font-bold">{text}</span>
        </div>
    )
}

function Impressum() {
    return (
        <div className="flex flex-grow flex-col p-8 w-full bg-slate-400">
            <div className="flex flex-row items-center justify-center w-fit my-8">
                <span className="text-slate-900 font-bold text-5xl text-nowrap">
                    Impressum
                </span>
            </div>
            <div className="flex flex-col justify-center items-start mt-8">
                <span className="text-slate-900 font-bold text-xl">
                    Angaben gemäß DDG | Verantwortlich im Sinne der DSGVO:
                </span>
                <div className="h-4"/>
                <span className="text-slate-900 font-semibold text-lg">
                    Michel Finley Bromund<br/>
                    <a href="mailto:michelfinley@proton.me"
                       className="link text-blue-700 hover:text-blue-800">michelfinley@proton.me</a>
                </span>
            </div>
            <div className="my-8" id="haftungfuerinhalte"/>
            <div className="flex flex-col justify-center items-start max-w-[685px]">
                <span className="text-slate-900 font-bold text-xl">
                    Haftung für Inhalte
                </span>
                <div className="h-4"/>
                <span className="text-slate-900 font-semibold text-sm">
Als Diensteanbieter bin ich für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
verantwortlich. Ich bin als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit
hinweisen.
                </span>
                <div className="h-4"/>
                <span className="text-slate-900 font-semibold text-sm">
Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werde ich diese Inhalte umgehend
entfernen.
                </span>
            </div>
            <div className="my-8" id="haftungfuerlinks"/>
            <div className="flex flex-col justify-center items-start max-w-[685px]">
                <span className="text-slate-900 font-bold text-xl">
                    Haftung für Links
                </span>
                <div className="h-4"/>
                <span className="text-slate-900 font-semibold text-sm">
Diese Webseite enthält Links zu externen Webseiten Dritter, auf deren Inhalte
ich keinen Einfluss habe. Daher kann ich für diese fremden Inhalte auch
keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                </span>
                <div className="h-4"/>
                <span className="text-slate-900 font-semibold text-sm">
Eine permanente Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
Anhaltspunkte einer Rechtsverletzung unzumutbar. Bei Bekanntwerden von
Rechtsverletzungen werde ich derartige Links umgehend entfernen.
                </span>
            </div>
            <div className="my-8" id="urheberrecht"/>
            <div className="flex flex-col justify-center items-start max-w-[685px]">
                <span className="text-slate-900 font-bold text-xl">
                    Urheberrecht
                </span>
                <div className="h-4"/>
                <span className="text-slate-900 font-semibold text-sm">
Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Webseiten
unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                </span>
                <div className="h-4"/>
                <span className="text-slate-900 font-semibold text-sm">
Soweit die Inhalte auf diesen Seiten nicht vom Betreiber erstellt wurden,
werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden
von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                </span>
            </div>
            <div className="my-8" id="datenschutz"/>
            <div className="flex flex-col justify-center items-start max-w-[685px] my-8">
                <span className="text-slate-900 font-bold text-3xl truncate min-w-0 max-w-full">
                    Datenschutzerklärung
                </span>
                <span className="text-slate-900 font-semibold text-sm">
<DatenschutzHeader id="0" text="Einleitung"/>
<DatenschutzSubHeader id="" text=""/>
Mit den nachfolgenden Informationen will ich Ihnen einen Überblick über die
Verarbeitung Ihrer personenbezogenen Daten auf den Webseiten <span className="font-mono text-orange-900">michelfinley.de</span>, <span className="font-mono text-orange-900">www.michelfinley.de</span>, <span className="font-mono text-orange-900">ai.michelfinley.de</span> & <span className="font-mono text-orange-900">api.michelfinley.de</span> (nachfolgend
"Webseiten" genannt) geben. Ich möchte Sie ebenfalls über Ihre Rechte
aus den Datenschutzgesetzen informieren. Die Verarbeitung Ihrer personenbezogenen
Daten durch mich erfolgt stets im Einklang mit der Datenschutz-Grundverordnung
(nachfolgend "DSGVO" genannt) sowie allen weiteren, in Deutschland geltenden Datenschutzbestimmungen.
                </span>
                <span className="text-slate-900 font-semibold text-sm">
<DatenschutzHeader id="0-9" text="Inhalt"/>
<DatenschutzSiteLink id="1" text="1. Bereitstellung der Website"/>
<DatenschutzSiteLink id="2" text="2. Cookies"/>
                </span>
            </div>
            <div className="flex flex-col justify-center items-start max-w-[685px]">
                <DatenschutzHeader id="1" text="1. Bereitstellung der Website"/>
                <span className="text-slate-900 font-semibold text-sm">
<DatenschutzSubHeader id="1-1" text="1.1 Allgemeine Informationen"/>
Bei Besuch dieser Webseiten werden automatisch Daten von Ihrem Browser an den
Server übermittelt und in Textdateien (nachfolgend auch "Logs" genannt) auf dem Server gespeichert.
<DatenschutzSubHeader id="" text=""/>
Diese Daten umfassen:
<ul>
    <li>
- User-Agent (Browsertyp, Browserversion und das verwendete Betriebssystem)
    </li>
    <li>
- Referrer-URL (zuvor besuchte Website)
    </li>
    <li>
- Datum und Uhrzeit der Serveranfrage
    </li>
    <li>
- Anfragemethode und die angefragte URL
    </li>
    <li>
- IP-Adresse des zugreifenden Geräts
    </li>
</ul>
<DatenschutzSubHeader id="" text="" />
Alle über diese Daten hinausgehenden Informationen werden serverseitig nicht gespeichert.
Durch diese Daten werden keine Rückschlüsse auf Ihre Person getroffen.
                </span>
                <span className="text-slate-900 font-semibold text-sm">
<DatenschutzSubHeader id="1-2" text="1.2 Zweck der Verarbeitung" />
Die Erfassung dieser Daten erfüllt folgende Zwecke:
<ul>
    <li>
- Gewährleistung eines reibungslosen Verbindungsaufbaus zur Website
    </li>
    <li>
- Aufklärung von Missbrauchs- oder Betrugshandlungen
    </li>
    <li>
- Problemanalysen
    </li>
    <li>
- Auswertung der Systemsicherheit und -stabilität
    </li>
</ul>
                </span>
                <span className="text-slate-900 font-semibold text-sm">
<DatenschutzSubHeader id="1-3" text="1.3 Rechtsgrundlage" />
Als Rechtsgrundlage hierfür gilt Art. 6 Abs. 1 S. 1 lit. f DSGVO.
<br/>
Ich habe ein überwiegendes berechtigtes Interesse daran, diese Webseiten technisch einwandfrei anbieten zu können.
                </span>
                <span className="text-slate-900 font-semibold text-sm">
<DatenschutzSubHeader id="1-4" text="1.4 Speicherdauer" />
Die Logs werden aus Sicherheitsgründen zu o. g. Zwecken für die Dauer von maximal
14 Tagen gespeichert und danach gelöscht. Daten, deren weitere Aufbewahrung zu
Beweiszwecken erforderlich ist, werden bis zur endgültigen Klärung der Angelegenheit aufbewahrt.
                </span>
            </div>
            <div className="flex flex-col justify-center items-start max-w-[685px]">
                <DatenschutzHeader id="2" text="2. Cookies" />
                <span className="text-slate-900 font-semibold text-sm">
<DatenschutzSubHeader id="2-1" text="2.1 Allgemeine Informationen"/>
Die Website <span className="font-mono text-orange-900">ai.michelfinley.de</span> nutzt Cookies, d.h. von Ihrem Browser erstellte und auf
Ihrem Gerät gespeicherte Informationen.
Diese Cookies sind technisch notwendig, um die Nutzerauthentifizierung auf dieser Unterseite sicherzustellen.
<DatenschutzSubHeader id="2-2" text="2.2 Rechtsgrundlage"/>
Als Rechtsgrundlage hierfür gelten Art. 6 Abs. 1 S. 1 lit. a und f der DSGVO.
<br/>
Die Cookies sind notwendig um Sie als Nutzer authentifizieren zu können
und werden erst nach der Anmeldung bzw. Registrierung auf nutzerspezifische Werte gesetzt.
                </span>
            </div>
            <div className="my-8" />
            <div className="my-8 max-w-[685px]">
                <span className="text-slate-900 font-normal text-sm">
                    Written social-media posts as seen on <span
                    className="font-mono text-orange-900">ai.michelfinley.de</span> and on screenshots on <span
                    className="font-mono text-orange-900">michelfinley.de/ai</span> have been AI-generated using
                    Meta's <Lnk href="https://huggingface.co/meta-llama/Llama-2-13b-chat-hf" text="Llama 2 13B Chat" />.
                    The exact model used was published by TheBloke
                    on <Lnk href="https://huggingface.co/TheBloke/Llama-2-13B-chat-GGUF" text="huggingface.co" />.
                    All other media on those websites, including all profile images, names and data fed to the AI during
                    content generation was gathered from
                    the <Lnk href="https://comicvine.gamespot.com/api/" text="ComicVine API" />.<br/>

                    <br/>

                    Screenshots on <span className="font-mono text-orange-900">michelfinley.de/towerdefense</span> may
                    contain visual assets
                    by <Lnk href="https://pillowbyte.itch.io/diceforce-free-assets" text="Pillowbyte / pillowrd" /> and
                    fonts by <Lnk href="https://www.fontspace.com/azonix-font-f30718" text="mixofx" /> and <Lnk
                    href="https://www.fontspace.com/pixel-tandysoft-font-f21543" text="Pixel Sagas" />.<br/>

                    <br/>

                    Material Symbols is an official icon set from Google Inc.<br/>
                    It can be found on <Lnk href="https://fonts.google.com/icons" text="https://fonts.google.com/icons" /><br/>

                    <br/>

                    GitHub INVERTOCAT logo taken from <a href="https://brand.github.com/foundations/logo"
                                                         className="text-blue-700 link hover:text-blue-800">https://brand.github.com/foundations/logo</a><br/>
                    The INVERTOCAT logo design is a trademark of GitHub, Inc., registered in the United States and other countries.
                </span>
            </div>
        </div>
    );
}

export default Impressum;
