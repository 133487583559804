import React, {useEffect, useRef, useState} from 'react';
import {Link, Outlet, useLocation} from "react-router-dom";
import NavigationModal from "./NavigationModal";

function EN_Base() {
    const location = useLocation();

    const [isNavigationModalOpen, setNavigationModalOpen] = useState<boolean>(false);

    const openNavigationModal = () => {
        setNavigationModalOpen(true);
    };

    const closeNavigationModal = () => {
        setNavigationModalOpen(false);
    };

    const ignoreEffect = useRef<boolean>(false);
    useEffect(() => {
        if (!ignoreEffect.current) {
            ignoreEffect.current = true;
            document.title = "Michel Finley | Portfolio";
        }
    }, []);

    return (
        <div className="relative flex flex-col justify-center items-center w-full h-fit min-h-full">
            <div className="fixed top-0 left-0 right-0 flex flex-row justify-center items-center w-full h-12
                            bg-slate-400 z-10">
                <Link to="/en/" className="w-fit mx-8 h-full flex items-center justify-center">
                    <span className="font-semibold text-xl text-white">
                        michelfinley
                    </span>
                </Link>
                <div className="flex flex-grow"/>
                <div className="hidden lg:flex flex-row justify-between items-center h-full mx-8">
                    <Link to="/en/ai" className="mx-4 w-fit">
                        <span className="font-semibold text-lg text-white link hover:text-slate-200">
                            @i
                        </span>
                    </Link>
                    <Link to="/en/towerdefense" className="mx-4 w-fit">
                        <span className="font-semibold text-lg text-white link hover:text-slate-200">
                            towerdefense
                        </span>
                    </Link>
                    <Link to="/en/impressum" className="mx-4 w-fit">
                        <span className="font-semibold text-lg text-white link hover:text-slate-200">
                            impressum
                        </span>
                    </Link>
                    <div className="mx-4 w-fit h-fit flex flex-row items-center justify-start">
                        <Link to={`${location.pathname.substring(3)}`} className="w-fit">
                            <span className="font-normal text-base text-white link hover:text-slate-200">
                                de
                            </span>
                        </Link>
                        <div className="mx-1 mb-0.5 w-fit">
                            <span className="font-normal text-base text-white">
                                |
                            </span>
                        </div>
                        <Link to={`${location.pathname}`} className="w-fit">
                            <span className="font-normal text-base text-white link hover:text-slate-200">
                                en
                            </span>
                        </Link>
                    </div>
                </div>
                <div className="flex lg:hidden justify-center items-center h-full mx-8">
                    <button onClick={openNavigationModal} className="flex justify-center items-center mx-4">
                        <span className="material-symbols material-symbol-2xl text-white hover:text-slate-200">
                            menu
                        </span>
                    </button>
                </div>
            </div>
            <div className="fixed top-0 left-0 right-0 w-full mt-12 h-[1px] bg-slate-50 z-10"/>

            <NavigationModal isOpen={isNavigationModalOpen}  onClose={closeNavigationModal}/>

            <div className="h-12 min-h-12"/>
            <div className="flex flex-grow w-full">
                <Outlet/>
            </div>
        </div>
    );
}

export default EN_Base;
