import React, {useCallback, useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";


interface NavigationModalProps {
    isOpen: boolean,
    onClose: () => void,
}


const NavigationModal: React.FC<NavigationModalProps> = (
    {isOpen, onClose,}
) => {
    const location = useLocation();

    const [isModalOpen, setModalOpen] = useState(isOpen);
    const modalRef = useRef<HTMLDialogElement | null>(null);

    const mainElementRef = useRef<HTMLDivElement | null>(null);

    const handleClose = useCallback(() => {
        onClose();
        setModalOpen(false);
    }, [onClose]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
        if (event.key === "Escape") {
            handleClose();
        }
    };

    useEffect(() => {
        setModalOpen(isOpen)
    }, [isOpen]);

    useEffect(() => {
        const modalElement = modalRef.current;

        if (modalElement) {
            if (isModalOpen) {
                modalElement.showModal();
            } else {
                modalElement.close();
            }
        }

    }, [isModalOpen]);

    useEffect(() => {
        if (isModalOpen) {
            const onClick = (event: { target: any; }) => {
                if (mainElementRef.current && !mainElementRef.current.contains(event.target)) {
                    handleClose();
                }
            }

            document.addEventListener("mouseup", onClick);

            return () => {
                document.removeEventListener("mouseup", onClick);
            };
        }
    }, [handleClose, isModalOpen, mainElementRef]);

    return (
        <dialog ref={modalRef} onKeyDown={handleKeyDown}
                className="fixed w-[40%] min-w-fit h-full min-h-full top-0 bottom-0 right-0 left-auto m-0
                           bg-transparent
                           backdrop:backdrop-brightness-50">

            <div ref={mainElementRef} className="relative flex items-center justify-center w-full h-full">

                <div className="w-full h-full bg-slate-500 rounded-l-xl">
                    <button onClick={handleClose}
                            className="flex justify-center items-center top-0 left-0 m-2 px-4 py-2
                                       bg-slate-600 rounded-full text-slate-200
                                       focus:outline-none">
                        <span className="mt-[3px] material-symbols">arrow_back</span>
                        <span className="ml-1 font-semibold">{`${
                            location.pathname.substring(0, 3) === "/en" ?
                                "Go Back" :
                                "Zurück"
                        }`}</span>
                    </button>

                    <div className="mx-8 flex flex-col items-start">
                        <Link to={`${
                            location.pathname.substring(0, 3) === "/en" ?
                                "/en/ai" :
                                "/ai"
                        }`} onClick={handleClose} className="my-4 w-fit">
                            <span className="font-semibold text-lg text-white link hover:text-slate-200">
                                @i
                            </span>
                        </Link>
                        <Link to={`${
                            location.pathname.substring(0, 3) === "/en" ?
                                "/en/towerdefense" :
                                "/towerdefense"
                        }`} onClick={handleClose} className="my-4 w-fit">
                            <span className="font-semibold text-lg text-white link hover:text-slate-200">
                                towerdefense
                            </span>
                        </Link>
                        <Link to={`${
                            location.pathname.substring(0, 3) === "/en" ?
                                "/en/impressum" :
                                "/impressum"
                        }`} onClick={handleClose} className="my-4 w-fit">
                            <span className="font-semibold text-lg text-white link hover:text-slate-200">
                                impressum
                            </span>
                        </Link>
                        <div className="my-8 w-fit h-fit flex flex-row items-center justify-start">
                            <Link to={`${
                                location.pathname.substring(0, 3) === "/en" ?
                                    location.pathname.substring(3) :
                                    location.pathname
                            }`} onClick={handleClose} className="w-fit">
                                <span className="font-normal text-base text-white link hover:text-slate-200">
                                    de
                                </span>
                            </Link>
                            <div className="mx-1 mb-0.5 w-fit">
                            <span className="font-normal text-base text-white">
                                |
                            </span>
                            </div>
                            <Link to={`${
                                location.pathname.substring(0, 3) === "/en" ?
                                    location.pathname :
                                    `/en${location.pathname}`
                            }`} onClick={handleClose} className="w-fit">
                            <span className="font-normal text-base text-white link hover:text-slate-200">
                                en
                            </span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </dialog>
    );
};

export default NavigationModal;
