import React from 'react';
import {isRouteErrorResponse, useRouteError} from "react-router-dom";

function ErrorPage() {
    const error = useRouteError();
    let errorMessage: string;

    if (isRouteErrorResponse(error)) {
        errorMessage = error.data.errorMessage;
    } else if (error instanceof Error) {
        errorMessage = error.message;
    } else if (typeof error === 'string') {
        errorMessage = error;
    } else {
        console.error(error);
        errorMessage = 'Unknown error';
    }

    return (
        <div className="flex flex-grow flex-col items-center justify-center w-full h-full bg-slate-400">
            <div className="flex flex-row justify-center items-center w-full my-4 mt-12">
                <span className="text-4xl font-bold text-slate-800">
                    Oops!
                </span>
            </div>
            <div className="flex justify-center items-center w-full my-4">
                <span className="italic text-slate-800">
                    An unexpected error has occurred
                </span>
            </div>
            <div className="flex justify-center items-center w-full my-4">
                <span className="text-sm italic text-red-700">
                    Detail: {errorMessage}
                </span>
            </div>
        </div>
    );
}

export default ErrorPage;
