import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";

import {Ai, ErrorPage, Impressum, MainPage, NotFoundPage, Towerdefense} from "./routes/de";
import {EN_Ai, EN_ErrorPage, EN_Impressum, EN_MainPage, EN_NotFoundPage, EN_Towerdefense} from "./routes/en";

import Base from "./BaseComponent";
import EN_Base from "./EN_BaseComponent";

import './index.css';
import './MaterialSymbolsOutlined.css';

const router = createBrowserRouter(
    createRoutesFromElements([
        <Route path="/en/*" element={<EN_Base />} errorElement={<EN_ErrorPage />}>
            <Route path="" element={<EN_MainPage />} errorElement={<EN_ErrorPage />} />,
            <Route path="ai" element={<EN_Ai />} errorElement={<EN_ErrorPage />} />,
            <Route path="towerdefense" element={<EN_Towerdefense />} errorElement={<EN_ErrorPage />} />,
            <Route path="impressum" element={<EN_Impressum />} errorElement={<EN_ErrorPage />} />,
            <Route path="*" element={<EN_NotFoundPage />} errorElement={<EN_ErrorPage />} />,
        </Route>,

        <Route path="*" element={<Base />} errorElement={<ErrorPage />}>
            <Route path="" element={<MainPage />} errorElement={<ErrorPage />} />,
            <Route path="ai" element={<Ai />} errorElement={<ErrorPage />} />,
            <Route path="towerdefense" element={<Towerdefense />} errorElement={<ErrorPage />} />,
            <Route path="impressum" element={<Impressum />} errorElement={<ErrorPage />} />,
            <Route path="*" element={<NotFoundPage />} errorElement={<ErrorPage />} />,
        </Route>,
    ])
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);
