import React, {useCallback, useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";


interface SettingsModalProps {
    isOpen: boolean,
    onClose: () => void,

    image: string,
}


const ImageModal: React.FC<SettingsModalProps> = (
    {isOpen, onClose, image,}
) => {
    const location = useLocation();

    const [isModalOpen, setModalOpen] = useState(isOpen);
    const modalRef = useRef<HTMLDialogElement | null>(null);

    const mainElementRef = useRef<HTMLDivElement | null>(null);

    const handleClose = useCallback(() => {
        onClose();
        setModalOpen(false);
    }, [onClose]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
        if (event.key === "Escape") {
            handleClose();
        }
    };

    useEffect(() => {
        setModalOpen(isOpen)
    }, [isOpen]);

    useEffect(() => {
        const modalElement = modalRef.current;

        if (modalElement) {
            if (isModalOpen) {
                modalElement.showModal();
            } else {
                modalElement.close();
            }
        }

    }, [isModalOpen]);

    useEffect(() => {
        if (isModalOpen) {
            const onClick = (event: { target: any; }) => {
                if (mainElementRef.current && !mainElementRef.current.contains(event.target)) {
                    handleClose();
                }
            }

            document.addEventListener("mouseup", onClick);

            return () => {
                document.removeEventListener("mouseup", onClick);
            };
        }
    }, [handleClose, isModalOpen, mainElementRef]);

    return (
        <dialog ref={modalRef} onKeyDown={handleKeyDown}
                className={`fixed w-fit h-fit m-auto
                            bg-transparent
                            backdrop:backdrop-brightness-50`}>

            <div ref={mainElementRef}
                 className="relative flex items-center justify-center w-fit h-fit rounded-xl overflow-hidden">

                <button onClick={handleClose}
                        className="absolute flex justify-center items-center top-0 right-0 w-fit h-8 m-2
                                   bg-slate-100 rounded-full opacity-70">
                    <span className="text-slate-900 font-semibold mb-0.5 ml-2">{`${
                        location.pathname.substring(0, 3) === "/en" ?
                            "Close" :
                            "Schließen"
                    }`}</span>
                    <span className="material-symbols text-slate-900 w-8">close</span>
                </button>

                <img src={image} alt={image}/>
            </div>
        </dialog>
    );
};

export default ImageModal;
