import ai_explore from "./ai_explore_570x830.png";
import ai_explore_navbar from "./ai_explore_navbar_830x570.png";
import ai_favorites from "./ai_favorites_570x830.png";
import ai_following from "./ai_following_570x830.png";
import ai_login from "./ai_login_570x830.png";
import ai_search from "./ai_search_570x830.png";
import ai_settings from "./ai_settings_570x830.png";
import ai_settingsmodal from "./ai_settingsmodal_570x830.png";
import ai_lg_search from "./ai_search_1162x798.png";
import ai_lg_settings from "./ai_settings_1162x798.png";
import ai_xl_following from "./ai_following_1290x785.png";

import td_available from "./td_available_1920x1009.png";
import td_firing from "./td_firing_1920x1009.png";
import td_impact from "./td_impact_1920x1009.png";
import td_impact2 from "./td_impact2_1920x1009.png";
import td_laser_charge from "./td_laser_charge_1920x1009.png";
import td_laser_firing from "./td_laser_firing_1920x1009.png";
import td_late from "./td_late_1920x1009.png";
import td_next_wave from "./td_next_wave_1920x1009.png";
import td_paused from "./td_paused_1920x1009.png";
import td_title_screen from "./td_title_screen_1920x1009.png";

import ai_logo from "./ai_logo512.png";
import td_logo from "./td_logo512.png";

import github_logo from "./GitHub_Invertocat_Dark.png";

export const AI_EXPLORE = ai_explore;
export const AI_EXPLORE_NAVBAR = ai_explore_navbar;
export const AI_FAVORITES = ai_favorites;
export const AI_FOLLOWING = ai_following;
export const AI_LOGIN = ai_login;
export const AI_SEARCH = ai_search;
export const AI_SETTINGS = ai_settings;
export const AI_SETTINGSMODAL = ai_settingsmodal;
export const AI_LG_SEARCH = ai_lg_search;
export const AI_LG_SETTINGS = ai_lg_settings;
export const AI_XL_FOLLOWING = ai_xl_following;

export const TD_AVAILABLE = td_available;
export const TD_FIRING = td_firing;
export const TD_IMPACT = td_impact;
export const TD_IMPACT2 = td_impact2;
export const TD_LASER_CHARGE = td_laser_charge;
export const TD_LASER_FIRING = td_laser_firing;
export const TD_LATE = td_late;
export const TD_NEXT_WAVE = td_next_wave;
export const TD_PAUSED = td_paused;
export const TD_TITLE_SCREEN = td_title_screen;

export const AI_LOGO = ai_logo;
export const TD_LOGO = td_logo;

export const GITHUB_LOGO = github_logo;