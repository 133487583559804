import React, {useState} from 'react';
import {
    AI_EXPLORE, AI_EXPLORE_NAVBAR, AI_FAVORITES, AI_FOLLOWING, AI_XL_FOLLOWING,
    AI_LOGO, AI_SEARCH, AI_SETTINGS, AI_SETTINGSMODAL, GITHUB_LOGO, AI_LG_SEARCH, AI_LG_SETTINGS, AI_LOGIN,
} from "../../res";
import ImageModal from "../../ImageModal";

function BackendCode({href, text}: { href: string, text: string }) {
    return (
        <a href={href} className="link text-sky-700 hover:text-sky-800">
            {text}
        </a>
    )
}

function FrontendCode({href, text}: { href: string, text: string }) {
    return (
        <a href={href} className="link text-emerald-700 hover:text-emerald-800">
            {text}
        </a>
    )
}

function Lnk({href, text}: { href: string, text: string }) {
    return (
        <a href={href} className="link text-blue-700 hover:text-blue-800">
            {text}
        </a>
    )
}

function Beschreibung() {
    return (
<span className="text-slate-900 font-semibold text-xl inline-block">
    @i ist das Ergebnis meines ersten komplexeren Website-Projekts, das Front- und Backend mit einer KI-generierten
    Social-Media-Post-Datenbank kombiniert, die ich mit
    Meta's <Lnk href="https://huggingface.co/meta-llama/Llama-2-13b-chat-hf" text="Llama 2 13B Chat" /> erstellt habe.
    Das genaue Modell das ich benutzt habe wurde von TheBloke
    auf <Lnk href="https://huggingface.co/TheBloke/Llama-2-13B-chat-GGUF" text="huggingface.co" /> veröffentlicht.
    Alle anderen Medien, einschließlich aller Profilbilder, Namen und den Daten, die der KI während der Postgenerierung
    übergeben werden, wurden der <Lnk href="https://comicvine.gamespot.com/api/" text="ComicVine API" /> entnommen.
    <br/>
    <br/>
    Zu den wichtigsten Code-Modulen, die ich für diese App programmiert habe,
    gehören <BackendCode href="https://github.com/michelfinley/api.michelfinley.de/blob/master/services.py#L43-L109" text="CRUD-Endpunkte" />,
    das <BackendCode href="https://github.com/michelfinley/api.michelfinley.de/blob/master/services.py#L112-L169" text="Benutzerauthentifizierungssystem" /> und
    die <BackendCode href="https://github.com/michelfinley/api.michelfinley.de/blob/master/services.py#L217-L304" text="Suchfunktion" /> auf
    dem FastAPI-Backend-Server,
    der <BackendCode href="https://github.com/michelfinley/api.michelfinley.de/blob/master/services.py#L217-L304" text="Content-Loader" /> <FrontendCode href="https://github.com/michelfinley/ai.michelfinley.de/blob/master/src/components/ContentLoader.tsx" text="[frontend]" /> sowohl
    im Front- als auch im Backend, welcher den Überblick über die bereits an den Benutzer gesendeten Beiträge behält,
    sowie <FrontendCode href="https://github.com/michelfinley/ai.michelfinley.de/blob/master/src/components/myaccount/SettingsModal.tsx" text="modale Dialoge" />, <FrontendCode href="https://github.com/michelfinley/ai.michelfinley.de/blob/master/src/context/NotificationContext.tsx" text="Toast-Benachrichtigungen" /> und
    responsives Design im React-Frontend.
</span>
    )
}

function Ai() {

    const [isImageModalOpen, setImageModalOpen] = useState<boolean>(false);
    const [currentImageModalImage, setCurrentImageModalImage] = useState<string>("");

    const openImageModal = () => {
        setImageModalOpen(true);
    };

    const closeImageModal = () => {
        setImageModalOpen(false);
    };

    return (
        <div className="flex flex-grow flex-col p-2 lg:p-8 w-full bg-slate-400">
            <div className="flex flex-col lg:flex-row items-center justify-center w-full lg:w-fit min-w-0 max-w-full my-8">
                <div className="flex items-center justify-center w-[128px] min-w-[128px] h-[128px] min-h-[128px]
                                rounded-full overflow-hidden">
                    <img src={AI_LOGO} className="" alt="AI_LOGO"/>
                </div>
                <div className="w-8 h-8"/>
                <span className="hidden sm:inline text-slate-900 font-bold text-5xl">
                    @i - artificial social media
                </span>
                <span className="sm:hidden text-slate-900 font-bold text-3xl max-w-full truncate">
                    @i - artificial social media
                </span>
            </div>
            <div className="my-8 min-w-0 max-w-full">
                <span className="hidden sm:inline text-slate-900 font-bold text-3xl">
                    Projektzeitraum: März 2024 - Juni 2024
                </span>
                <div className="sm:hidden flex flex-col">
                    <span className="text-slate-900 font-bold text-3xl max-w-full truncate">Projektzeitraum:</span>
                    <span className="px-4 text-slate-900 font-bold text-3xl max-w-full truncate">März 2024 - Juni 2024</span>
                </div>
            </div>
            <div className="my-8">
                <span className="text-slate-900 font-bold text-3xl text-nowrap">
                    Übersicht:
                </span>
                <div className="flex flex-col-reverse lg:flex-row w-full mt-2">
                    <div className="flex flex-col mx-4 min-w-0">
                        <Beschreibung/>
                        <div className="my-4"/>
                        <div className="hidden md:flex flex-row h-7 text-slate-900 font-bold text-xl text-nowrap">
                            GitHub:
                            <img src={GITHUB_LOGO} className="ml-3 mr-1" alt="GITHUB"/>
                            <a href="https://github.com/michelfinley/ai.michelfinley.de" className="link">michelfinley/ai.michelfinley.de</a>
                            <img src={GITHUB_LOGO} className="ml-3 mr-1" alt="GITHUB"/>
                            <a href="https://github.com/michelfinley/api.michelfinley.de" className="link">michelfinley/api.michelfinley.de</a>
                        </div>
                        <div className="flex md:hidden flex-col text-slate-900 font-bold text-xl text-nowrap">
                            <div className="flex flex-row h-7">
                                <img src={GITHUB_LOGO} className="mr-1" alt="GITHUB"/>
                                <a href="https://github.com/michelfinley/ai.michelfinley.de" className="link">michelfinley/ai.michelfinley.de</a>
                            </div>
                            <div className="h-2" />
                            <div className="flex flex-row h-7">
                                <img src={GITHUB_LOGO} className="mr-1" alt="GITHUB"/>
                                <a href="https://github.com/michelfinley/api.michelfinley.de" className="link">michelfinley/api.michelfinley.de</a>
                            </div>
                            <div className="h-2" />
                            <div>
                                <Lnk href="https://ai.michelfinley.de" text="https://ai.michelfinley.de"/>
                            </div>
                        </div>
                        <div className="h-2"/>
                        <span className="hidden md:inline text-slate-900 font-bold text-xl text-nowrap">
                            Webseite: <Lnk href="https://ai.michelfinley.de" text="https://ai.michelfinley.de"/>
                        </span>
                    </div>

                    <div className="flex sm:hidden flex-col w-full p-4">
                        <div className="flex flex-col w-fit mb-2">
                            <div className="text-slate-900 font-semibold text-xl font-mono">
                                HTML & CSS
                            </div>
                            <div className="w-full h-[1px] bg-slate-900"/>
                            <div className="flex flex-wrap justify-center items-start w-fit
                                            text-slate-900 text-base font-mono">
                                <span className="mr-2">TailwindCSS</span>
                            </div>
                        </div>
                        <div className="flex flex-col w-fit my-2">
                            <div className="text-slate-900 font-semibold text-xl font-mono">
                                TypeScript
                            </div>
                            <div className="w-full h-[1px] bg-slate-900"/>
                            <div
                                className="flex flex-wrap justify-center items-start w-fit text-slate-900 text-base font-mono">
                                <span className="mr-2">ReactJS</span>
                                <span className="mx-2">Express</span>
                            </div>
                        </div>
                        <div className="flex flex-col w-fit my-2">
                            <div className="text-slate-900 font-semibold text-xl font-mono">
                                Python
                            </div>
                            <div className="w-full h-[1px] bg-slate-900"/>
                            <div className="flex flex-wrap justify-center items-start w-fit
                                            text-slate-900 text-base font-mono">
                                <span className="mr-2">FastAPI</span>
                                <span className="mx-2">ctransformers</span>
                                <span className="mx-2">Simyan</span>
                                <span className="mx-2">argon2-cffi</span>
                            </div>
                        </div>
                    </div>

                    <table className="hidden sm:block border-collapse w-fit h-fit m-4 lg:my-0">
                        <thead className="bg-slate-800">
                        <tr>
                            <th className="py-2 px-2 text-white font-semibold text-base">Programmiersprachen</th>
                            <th colSpan={4}
                                className="py-2 px-2 text-start text-white font-semibold text-base">Frameworks &
                                Bibliotheken
                            </th>
                        </tr>
                        </thead>
                        <tbody className="bg-slate-600
                                          text-white font-normal text-base text-nowrap">
                        <tr className="border-t border-white">
                        <td className="py-2 px-2">HTML & CSS</td>
                            <td colSpan={4} className="py-2 px-2 font-mono text-slate-300">TailwindCSS</td>
                        </tr>
                        <tr className="border-t border-white">
                            <td className="py-2 px-2">TypeScript</td>
                            <td className="py-2 px-2 font-mono text-slate-300">React</td>
                            <td className="py-2 px-2 font-mono text-slate-300 text-base">Express</td>
                            <td/>
                            <td/>
                        </tr>
                        <tr className="border-t border-white">
                            <td className="py-2 px-2">Python</td>
                            <td className="py-2 px-2 font-mono text-slate-300">FastAPI</td>
                            <td className="py-2 px-2 font-mono text-slate-300">ctransformers</td>
                            <td className="py-2 px-2 font-mono text-slate-300 text-base">Simyan</td>
                            <td className="py-2 px-2 font-mono text-slate-300 text-base">argon2-cffi</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="w-full my-8">
                <span className="text-slate-900 font-bold text-3xl text-nowrap">
                    Screenshots
                </span>
                <div className="flex justify-around items-start w-full max-w-full flex-wrap">
                    <button onClick={() => {setCurrentImageModalImage(AI_LOGIN); openImageModal();}}
                            className="project-image-outline">
                        <img src={AI_LOGIN} alt="AI_LOGIN" className="project-image"/>
                    </button>
                    <button onClick={() => {setCurrentImageModalImage(AI_EXPLORE); openImageModal();}}
                            className="project-image-outline">
                        <img src={AI_EXPLORE} alt="AI_EXPLORE" className="project-image"/>
                    </button>
                    <button onClick={() => {setCurrentImageModalImage(AI_FAVORITES); openImageModal();}}
                            className="project-image-outline">
                        <img src={AI_FAVORITES} alt="AI_FAVORITES" className="project-image"/>
                    </button>
                    <button onClick={() => {setCurrentImageModalImage(AI_FOLLOWING); openImageModal();}}
                            className="project-image-outline">
                        <img src={AI_FOLLOWING} alt="AI_FOLLOWING" className="project-image"/>
                    </button>
                    <button onClick={() => {setCurrentImageModalImage(AI_SEARCH); openImageModal();}}
                            className="project-image-outline">
                        <img src={AI_SEARCH} alt="AI_FOLLOWING" className="project-image"/>
                    </button>
                    <button onClick={() => {setCurrentImageModalImage(AI_SETTINGS); openImageModal();}}
                            className="project-image-outline">
                        <img src={AI_SETTINGS} alt="AI_SETTINGS" className="project-image"/>
                    </button>
                    <button onClick={() => {setCurrentImageModalImage(AI_SETTINGSMODAL); openImageModal();}}
                            className="project-image-outline">
                        <img src={AI_SETTINGSMODAL} alt="AI_SETTINGSMODAL" className="project-image"/>
                    </button>
                    <button onClick={() => {setCurrentImageModalImage(AI_EXPLORE_NAVBAR); openImageModal();}}
                            className="project-image-outline">
                        <img src={AI_EXPLORE_NAVBAR} alt="AI_EXPLORE_NAVBAR" className="project-image"/>
                    </button>
                    <button onClick={() => {setCurrentImageModalImage(AI_XL_FOLLOWING); openImageModal();}}
                            className="project-image-outline">
                        <img src={AI_XL_FOLLOWING} alt="AI_XL_FOLLOWING" className="project-image"/>
                    </button>
                    <button onClick={() => {setCurrentImageModalImage(AI_LG_SEARCH); openImageModal();}}
                            className="project-image-outline">
                        <img src={AI_LG_SEARCH} alt="AI_LG_SEARCH" className="project-image"/>
                    </button>
                    <button onClick={() => {setCurrentImageModalImage(AI_LG_SETTINGS); openImageModal();}}
                            className="project-image-outline">
                        <img src={AI_LG_SETTINGS} alt="AI_LG_SETTINGS" className="project-image"/>
                    </button>
                </div>
            </div>
            <ImageModal isOpen={isImageModalOpen} onClose={closeImageModal} image={currentImageModalImage} />
        </div>
    );
}

export default Ai;
