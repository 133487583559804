import React from 'react';
import {
    AI_EXPLORE, AI_LOGO, TD_LOGO, TD_NEXT_WAVE,
} from "../../res";
import {Link} from "react-router-dom";

function MainPage() {
  return (
      <div className="flex flex-grow flex-col items-center justify-center w-full bg-slate-400">
          <div className="flex flex-col items-start md:items-center justify-center w-full">
              <span className="text-white font-bold text-5xl m-6">
                  Meine Projekte:
              </span>
          </div>
          <div className="h-2"/>
          <div className="flex flex-col items-center justify-center w-full p-6">
              <div className="flex md:hidden flex-col items-center justify-center w-full">
                  <Link to="/ai" className="flex flex-col w-full h-fit mx-8
                              bg-slate-500 rounded-xl overflow-hidden">

                      <div className="flex flex-row items-center justify-center w-full p-4">
                          <div className="flex items-center justify-center w-[48px] min-w-[48px] h-[48px] min-h-[48px]
                                rounded-full overflow-hidden">
                              <img src={AI_LOGO} className="" alt="AI_LOGO"/>
                          </div>
                          <div className="mx-2" />
                          <span className="text-white font-semibold text-xl sm:text-2xl">
                              @i - artificial social media
                          </span>
                      </div>
                      <div className="w-full h-[1px] bg-slate-300"/>
                      <div className="flex flex-grow flex-col justify-start items-center w-full p-4">
                          <span className="mt-2 text-white font-normal text-base">
                              Eine "künstliche" Social-Media-Plattform
                              mit hunderten KI-generierten Beiträgen aus der Sicht bekannter Comicfiguren.
                          </span>
                          <div className="h-8"/>
                          <span className="w-full text-slate-800 font-mono text-base">
                              HTML & CSS (tailwind)<br/>
                              TypeScript (React)<br/>
                              Python (FastAPI, ctransformers)
                          </span>
                      </div>
                  </Link>
                  <div className="h-8"/>
                  <Link to="/towerdefense" className="flex flex-col w-full h-fit mx-8
                              bg-slate-500 rounded-xl overflow-hidden">

                      <div className="flex flex-row items-center justify-center w-full p-4">
                          <div className="flex items-center justify-center w-[48px] min-w-[48px] h-[48px] min-h-[48px]
                                rounded-full overflow-hidden">
                              <img src={TD_LOGO} className="" alt="AI_LOGO"/>
                          </div>
                          <div className="mx-2"/>
                          <span className="text-white font-semibold text-xl sm:text-2xl">
                              towerdefense
                          </span>
                      </div>
                      <div className="w-full h-[1px] bg-slate-300"/>
                      <div className="flex flex-grow flex-col justify-start items-center w-full p-4">
                          <span className="mt-2 text-white font-normal text-base">
                              Ein simples, objektorientiertes Turmverteidigungsspiel mit Pixel-Grafik,
                              ohne Engine und in purem Python.
                          </span>
                          <div className="h-8"/>
                          <span className="w-full text-slate-800 font-mono text-base">
                              Python (pygame-ce)
                          </span>
                      </div>
                  </Link>
              </div>

              <div className="hidden md:flex flex-row items-center justify-between">
                  <Link to="/ai" className="flex flex-col w-[320px] h-[420px] mx-8
                              bg-slate-700 rounded-xl overflow-hidden shadow-lg shadow-slate-900
                              transform hover:scale-110 duration-150 ease-in-out">
                      <div className="w-[320px] h-[180px] overflow-hidden">
                          <img src={AI_EXPLORE} className="w-[320px] h-auto" alt="AI_EXPLORE"/>
                      </div>
                      <div className="w-full h-[1px] bg-slate-300"/>
                      <div className="flex flex-grow flex-col justify-start items-center w-full p-4">
                          <span className="text-white font-semibold text-lg">
                              @i - artificial social media
                          </span>
                          <span className="mt-2 text-white font-normal text-base">
                              Eine "künstliche" Social-Media-Plattform
                              mit hunderten KI-generierten Beiträgen aus der Sicht bekannter Comicfiguren.
                          </span>
                          <div className="flex flex-grow"/>
                          <span className="w-full text-slate-400 font-mono text-base">
                              HTML & CSS (tailwind)<br/>
                              TypeScript (React)<br/>
                              Python (FastAPI, ctransformers)
                          </span>
                      </div>
                  </Link>
                  <Link to="/towerdefense" className="flex flex-col w-[320px] h-[420px] mx-8
                              bg-slate-700 rounded-xl overflow-hidden shadow-lg shadow-slate-900
                              transform hover:scale-110 duration-150 ease-in-out">
                      <div className="flex items-center justify-center w-[320px] h-[180px] overflow-hidden">
                          <img src={TD_NEXT_WAVE} className="w-auto h-[180px]" alt="TD_NEXT_WAVE"/>
                      </div>
                      <div className="w-full h-[1px] bg-slate-300"/>
                      <div className="flex flex-grow flex-col justify-start items-center w-full p-4">
                          <span className="text-white font-semibold text-lg">
                              Towerdefense
                          </span>
                          <span className="mt-2 text-white font-normal text-base">
                              Ein simples, objektorientiertes Turmverteidigungsspiel mit Pixel-Grafik,
                              ohne Engine und in purem Python.
                          </span>
                          <div className="flex flex-grow"/>
                          <span className="w-full text-slate-400 font-mono text-base">
                              Python (pygame-ce)
                          </span>
                      </div>
                  </Link>
              </div>
          </div>
      </div>
  );
}

export default MainPage;
