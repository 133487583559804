import Ai from "./Ai";
import ErrorPage from "./ErrorPage";
import Impressum from "./Impressum";
import MainPage from "./MainPage";
import NotFoundPage from "./NotFoundPage";
import Towerdefense from "./Towerdefense";

export const EN_Ai = Ai;
export const EN_ErrorPage = ErrorPage;
export const EN_Impressum = Impressum;
export const EN_MainPage = MainPage;
export const EN_NotFoundPage = NotFoundPage;
export const EN_Towerdefense = Towerdefense;
